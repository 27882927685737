<template>
  <div id="app">
    <router-view />
  </div>
</template>

//在 src / app.vue 中 script 标签内添加代码
<script>
window.onload = function () {
  document.addEventListener("touchstart", function (e) {
    console.log("1", e.touches.length);
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (e) {
    console.log("2");
    e.preventDefault();
  });
};
</script>

<style lang="scss">
// #app {
//   // font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: #2c3e50;
// }
.v-modal {
  z-index: -1 !important;
}
</style>
