import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import mobileHome from '../components/mobileCompoen'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 走进恒联
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  // 产业布局
  {
    path: '/layoutView',
    name: 'layoutView',
    component: () => import('../views/layoutView.vue'),
  },
  // 产业布局 详情
  {
    path: '/layoutDetail',
    name: 'layoutDetail',
    component: () => import('../views/layoutDetail.vue'),
  },
  // 搜索页
  {
    path: '/SearchView',
    name: 'SearchView',
    component: () => import('../views/SearchView.vue'),
  },
  //  科技创新
  {
    path: '/technologyView',
    name: 'technologyView',
    component: () => import('../views/technologyView.vue'),
  },
  // 新闻动态
  {
    path: '/newsView',
    name: 'newsView',
    component: () => import('../views/newsView.vue'),
  },
  // 新闻动态 详情
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('../views/newsDetail.vue'),
  },
  //  可持续发展
  {
    path: '/developmentView',
    name: 'developmentView',
    component: () => import('../views/developmentView.vue'),
  },
  // 职业发展
  {
    path: '/professionalView',
    name: 'professionalView',
    component: () => import('../views/professionalView.vue'),
  },
  // 联系我们
  {
    path: '/relationView',
    name: 'relationView',
    component: () => import('../views/relationView.vue'),
  },

  // 移动端
  {
    path: '/hlmobile',
    name: 'hlmobile',
    component: mobileHome,
    children: [
      {
        path: 'mobileHome',
        name: 'mobileHome',
        component: () => import('../views/mobile/mobileHome.vue'),
      },
      {
        path: 'mobileAbout',
        name: 'mobileAbout',
        component: () => import('../views/mobile/mobileAbout.vue'),
      },
      {
        path: 'mobileLayout',
        name: 'mobileLayout',
        component: () => import('../views/mobile/mobileLayout.vue'),
      },
      {
        path: 'mLayoutDetail',
        name: 'mLayoutDetail',
        component: () => import('../views/mobile/mLayoutDetail.vue'),
      },
      {
        path: 'mobileTechnology',
        name: 'mobileTechnology',
        component: () => import('../views/mobile/mobileTechnology.vue'),
      },
      {
        path: 'mobileNew',
        name: 'mobileNew',
        component: () => import('../views/mobile/mobileNew.vue'),
      },
      {
        path: 'mNewDetail',
        name: 'mNewDetail',
        component: () => import('../views/mobile/mNewDetail.vue'),
      },
      {
        path: 'mobileDevelopment',
        name: 'mobileDevelopment',
        component: () => import('../views/mobile/mobileDevelopment.vue'),
      },
      {
        path: 'mobileProfessional',
        name: 'mobileProfessional',
        component: () => import('../views/mobile/mobileProfessional.vue'),
      },
      {
        path: 'mobileRelation',
        name: 'mobileRelation',
        component: () => import('../views/mobile/mobileRelation.vue'),
      },
      {
        path: 'mobileSearch',
        name: 'mobileSearch',
        component: () => import('../views/mobile/mobileSearch.vue'),
      },
    ]
  },
]

const userAgent = navigator.userAgent;
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (!/Mobile|iPhone|iPad|iPod|Android/i.test(userAgent)) {
    window.sessionStorage.removeItem("anchorPoint");
  }
  next() // 必须调用next()来resolve这个钩子
})

export default router