<template>
  <div>
    <mobileHead class="hader-view"></mobileHead>
    <router-view />
    <div class="mFoot">
      <div class="mFootTop">
        <div class="logo">
          <div class="logoLeft">
            <img src="../../assets/mobile/footLogo.png" alt="" />
          </div>
          <div class="logoRight" @click="mgoTop">
            <img src="../../assets/mobile/mgoTop.png" alt="" />
          </div>
        </div>
        <div class="mtabLink">
          <div class="mtab" v-for="(item, index) in mtabList" :key="index">
            <div class="mtabImg" @click="mtabClick(index)">
              <img :src="item.mtabImg" alt="" />
            </div>
            <transition name="fade">
              <img
                :class="'mtabTwo' + index"
                class="mtabTwo"
                :src="item.mtabImgTwo"
                v-show="index == imgindex"
              />
            </transition>
          </div>
        </div>
        <div class="mrelation">
          <div class="mPhone">
            <i class="iconfont">&#xe966;</i>
            {{ mphone }}
          </div>
          <div class="mPhone mEmail">
            <i class="iconfont">&#xe6af; </i>
            {{ memail }}
          </div>
        </div>
      </div>
      <div class="mFootBottom">
        <div class="bottomLeft">
          Copyright © 2013 - 2024 Henglian Group. All Rights Reserved.
        </div>
        <div class="bottomLeft bottomCenter">POWERED BY UG</div>
        <!-- <div class="bottomLeft bottomRight">隐私政策</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import mobileHead from "../mobileHead.vue";
export default {
  name: "mobile",
  components: {
    mobileHead,
  },
  data() {
    return {
      mtabList: [
        {
          mtabImg: require("../../assets/home/foot1.png"),
          mtabImgTwo: require("../../assets/home/weixin.jpg"),
        },
        {
          mtabImg: require("../../assets/home/foot2.png"),
          mtabImgTwo: require("../../assets/home/douyin.png"),
        },
        {
          mtabImg: require("../../assets/home/foot3.png"), 
          mtabImgTwo: require("../../assets/home/shipinhao.jpg"),
        },
      ],
      imgindex: 0,
      mphone: "0536-5033677",
      memail: "zcbgs@hengliangroup.com",
    };
  },
  mounted(){
    this.getContact()
  },
  methods: {
    mgoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    mtabClick(index) {
      this.imgindex = index;
    },
    getContact() {
      getContactus({}).then((data) => {
        // console.log(data, "联系我们");
        this.mphone = data.data.tel;
        this.memail = data.data.email;
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.hader-view {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px #ccc;
}

.fade-enter-active {
  transition: opacity 0.1s ease-in 0s;
  // scale: (1);
}

.fade-enter {
  opacity: 0;
  // scale: (0);
}
.mFoot {
  .mFootTop {
    padding: 0.21rem 0.14rem 0.3rem;
    background: #1b1b1b;
    .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.01rem solid #e3e3e4;
      padding-bottom: 0.2rem;
      img {
        width: 100%;
        height: 100%;
      }
      .logoLeft {
        width: 1.39rem;
        height: 0.19rem;
      }
      .logoRight {
        width: 0.24rem;
        height: 0.24rem;
      }
    }
    .mtabLink {
      display: flex;
      justify-content: space-between;
      padding: 0 0.92rem;
      margin-top: 0.43rem;
      .mtab {
        position: relative;
        .mtabImg {
          width: 0.3rem;
          height: 0.3rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .mtabTwo {
          position: absolute;
          top: 0.4rem;
          left: -0.26rem;
          box-sizing: border-box;
          width: 0.82rem;
          height: 0.82rem;
          background: #fff;
          box-shadow: 0px 2px 14px 0px rgba(22, 44, 112, 0.05);
          border-radius: 0.06rem;
          padding: 0.11rem;
          transition: all 0.3s linear;
        }
      }
    }
    .mrelation {
      margin-top: 1.1rem;
      padding: 0 0.66rem;
      .mPhone {
        color: #fff;
        font-size: 0.16rem;
        font-style: normal;
        font-weight: 700;
        line-height: 0.26rem;
        display: flex;
        align-items: center;
        i {
          color: #cd000c;
          font-weight: normal;
          font-size: 0.2rem;
          margin-right: 0.1rem;
        }
      }
      .mEmail {
        font-weight: 400;
      }
    }
  }
  .mFootBottom {
    background: #cd000c;
    padding: 0.1rem 0.14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bottomLeft {
      color: #e4e4e4;
      font-size: 0.1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.14rem;
      width: 1.95rem;
    }
    .bottomCenter {
      opacity: 0.5;
      width: max-content;
    }
    .bottomRight {
      width: max-content;
    }
  }
}
</style>