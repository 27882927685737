<template>
  <div>
    <div class="mhead">
      <div class="mheadImg">
        <img src="../assets/mobile/headImg.png" alt="" />
      </div>
      <div class="mheadRight">
        <div class="mlanguage" @click="changeLang(getLangData)">
          {{ getLangData == "CN" ? "EN" : "CN" }}
        </div>
        <el-button @click="drawer = true" label="ltr">
          <i class="iconfont">&#xe615;</i>
        </el-button>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :show-close="true"
      :with-header="false"
      :size="'100%'"
    >
      <div
        class="mhead"
        style="border-bottom: 0.005rem solid rgba(51, 51, 51, 0.2)"
      >
        <div class="mheadImg">
          <img src="../assets/mobile/headImg.png" alt="" />
        </div>
        <div class="mheadRight">
          <div class="mlanguage" @click="changeLang(getLangData)">
            {{ getLangData == "CN" ? "EN" : "CN" }}
          </div>
          <el-button @click="handleClose">
            <i class="iconfont">&#xe616;</i>
          </el-button>
        </div>
      </div>
      <div class="tabHeader">
        <router-link
          :to="item.path"
          v-for="(item, index) in headerList"
          :key="index"
        >
          <div class="headerTitle"  @click="handleClose">
            {{ item.name }}
          </div>
        </router-link>
      </div>
      <div class="msearch">
        <el-input
        :placeholder="inputPlace"
          v-model="searchValue"
          @keyup.enter="getSearchDataToPage"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getSearchDataToPage"
          ></el-button>
        </el-input>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      getLangData: localStorage.getItem("lang") || "CN",
      drawer: false,
      direction: "ltr",
      titleimg: require("../assets/mobile/headImg.png"),
      headerList: [
        {
          path: "/hlmobile/mobileHome",
          name: this.$t("header.index"),
        },
        {
          path: "/hlmobile/mobileAbout",
          name: this.$t("header.zoujin"),
        },
        {
          path: "/hlmobile/mobileLayout",
          name:  this.$t("header.chanye"),
        },
        {
          path: "/hlmobile/mobileTechnology",
          name: this.$t("header.zhuoyue"),
        },
        {
          path: "/hlmobile/mobileNew",
          name: this.$t("header.xinwen"),
        },
        {
          path: "/hlmobile/mobileDevelopment",
          name: this.$t("header.zeren"),
        },
        {
          path: "/hlmobile/mobileProfessional",
          name: this.$t("header.rencai"),
        },
        {
          path: "/hlmobile/mobileRelation",
          name: this.$t("header.lianxi"),
        },
      ],
      searchValue: "",
      inputPlace: this.$t("placeholder"),
    };
  },
  mounted() {
    if ((this.getLangData == "EN")) {
      this.headerList = this.headerList.filter(
        (item) => item.name !== 'NEWS CENTER'
      );
      this.headerList = this.headerList.filter(
        (item) => item.name !== 'GAREERS'
      );
    }
  },
  methods: {
    // 切换语言
    changeLang(lang) {
      // return
      // console.log(lang);
      let langTetx = lang === "CN" ? "EN" : "CN";
      localStorage.setItem("lang", langTetx);
      this.$i18n.locale = langTetx;
      window.location.reload();
    },
    handleClose() {
      this.drawer = false;
    },
    // 搜索
    getSearchDataToPage() {
      if (!this.searchValue) return;
      this.$router.push({
        path: "/hlmobile/mobileSearch",
        query: {
          search: this.searchValue,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  display: block;
}
::v-deep .v-modal {
  position: static;
}
.mhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.14rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px #ccc;
  .mheadImg {
    width: 101px;
    height: 0.18rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .mheadRight {
    display: flex;
    align-items: center;
    .mlanguage {
      font-size: 0.14rem;
      margin-right: 0.12rem;
    }
    i {
      font-size: 0.22rem;
    }
  }
}
::v-deep .el-button {
  padding: 0;
  border: 0;
}
::v-deep .el-button:focus, .el-button:hover,
::v-deep  .el-button:active{
  color: #606266;
  background-color: #fff;
  border-color: #fff;
}
.tabHeader {
  padding: 0 0.16rem;
  a {
    color: rgba(51, 51, 51, 1) !important;
    &.router-link-exact-active {
      color: rgba(205, 0, 12, 1) !important;
      .headerTitle {
        color: rgba(205, 0, 12, 1) !important;
        border-bottom: 0.005rem solid rgba(51, 51, 51, 0.2);
      }
    }
    .headerTitle {
      padding: 0.3rem 0 0.2rem 0;
      border-bottom: 0.005rem solid rgba(51, 51, 51, 0.2);
    }
  }
}
.msearch {
  width: 2.4rem;
  margin: 0.5rem auto 0;
  ::v-deep .el-input__inner {
    border: 0.01rem solid #eee;
    border-right: 0;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  ::v-deep .el-input-group__append {
    background-color: transparent;
    border: 0.01rem solid #eee;
    border-left: 0;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
</style>