<template>
  <div class="swiper" style="width: 100%; height: 100vh; position: relative">
    <!-- <swiper
      :options="swiperOption"
      ref="mySwiper"
      @slideChange="onSlideChange"
      @progress="onSwiperProgress"
    >
      <swiper-slide v-for="(item, index) in list" :key="index">
        <p class="title">{{ item.title1 }}<br />{{ item.title2 }}</p>
        <video
          id="myvideo"
          ref="videos"
          @canplay="getVidDur()"
          src="../assets/logo.mp4"
          autoplay
          muted
          @ended="playNextVideo(index)"
          type="video/mp4"
          style="width: 100%; height: 100vh"
          v-show="index == 0"
        ></video>
        <img
          v-show="index != 0"
          src="../assets/home/banner.png"
          alt=""
          style="width: 100%; height: 100vh"
          class="u-img default-out zoomout"
      /></swiper-slide>
     
      <div class="swiper-pagination" slot="pagination"></div>
    
      <div class="swiper-button-prev" slot="button-prev"></div>
     
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper> -->
    <el-carousel
      ref="carousel"
      height="100vh"
      indicator-position="none"
      :autoplay="autoplay"
      :autoplay-hover-pause="true"
      @change="carouselChange"
    >
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <!-- <p class="title" v-show="index != 0">
          {{ item.title }}<br />{{ item.subtitle }}
        </p> -->
        <p class="title" v-html="item.title"></p>
        <p class="title subtitle" v-html="item.subtitle"></p>
        <!-- <div class="but" @click="know(item)" v-show="index != 0">
          了解可持续发展 &nbsp;→
          <img src="../assets/home/jiantou.png" style="width: 0.2rem;height: 0.2rem;">
        </div> -->
        <div class="but" @click="know(item)">
          {{ item.name }} &nbsp;→
          <!-- <img src="../assets/home/jiantou.png" style="width: 0.2rem;height: 0.2rem;"> -->
        </div>
        <!-- v-if="index == 0" -->
        <!-- <video ref="videos"  :src="item.image" autoplay muted @ended="playNextVideo(index)"
          type="video/mp4" style="width: 100%; height: 100vh">
          您的浏览器不支持 video 标签。
        </video> -->
        <img
          ref="videos"
          :src="item.image"
          alt=""
          style="width: 100%; height: 100vh"
        />
      </el-carousel-item>
    </el-carousel>
    <!-- 进度展示 -->
    <div class="zixie">
      <div
        class="zixie-item"
        v-for="(item, index) in list"
        :key="index"
        @click="bannerjump(index)"
      >
        <div class="xian">
          <!-- <span class="jindu1" v-show="index == 0 && index == swiperindex"></span>
          <span class="jindu" v-show="index != 0 && index == swiperindex"></span> -->
          <span
            class="jindu1"
            v-show="index == 0 && index == swiperindex"
          ></span>
          <span class="jindu" v-show="index == swiperindex"></span>
        </div>
        <p class="title2">{{ item.title }}</p>
      </div>
    </div>
    <img class="xia" src="../assets/home/xia.png" alt="" />
  </div>
</template>
<script>
// var video = () => {
//   var videoTime = document.getElementById("myvideo");
//   console.log(videoTime.duration);
// };
import { getHomeBanner } from "@/api/index";
export default {
  components: {},
  data() {
    return {
      autoplay: true,
      list: [
        {
          title1: "以人为本",
          title2: "价值源于创新",
          img: require("../assets/home/banner.png"),
        },
        {
          title1: "绿色制造",
          title2: "溶汇天然价值，赋能美好生活",
          img: require("../assets/home/banner.png"),
        },
        {
          title1: "专精特新",
          title2: "专精特新",
          img: require("../assets/home/banner.png"),
        },
        {
          title1: "为爱守护",
          title2: "为爱守护",
          img: require("../assets/home/banner.png"),
        },
      ],
      swiperindex: 0,
      // swiperOption: {
      //   loop: true,
      //   pagination: {
      //     // 如果需要分页器
      //     el: ".swiper-pagination",
      //     // dynamicBullets: true, // 动态分页器，分页器小点的数量部分隐藏
      //     // dynamicMainBullets: 2, // 动态分页器的主指示点数量
      //     clickable: true, // 点击分页器的指示点控制swiper切换
      //     type: "bullets", // 分页器样式类型，默认bullets圆点 fraction分式，progressbar进度条，custom自定义
      //     progressbarOpposite: true, //使进度条分页器与Swiper的direction参数相反
      //     renderBullet: function (index, className) {
      //       console.log("index:", index);
      //       console.log("className:", className);
      //       // return '<span class="' + className + '" >' + (index + 1) + "</span>";
      //       return (
      //         '<span class="' +
      //         className +
      //         '" +v-show="index == 0"+>' +
      //         "以人为本，价值源于创新" +
      //         "</span>"
      //       );
      //     },
      //   },
      //   // scrollbar: {
      //   //   // 如果需要滚动条
      //   //   el: ".swiper-scrollbar",
      //   //   hide: false, // 滚动条是否自动隐藏，默认false
      //   // },
      //   // 设置点击箭头
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev",
      //   },
      //   // mousewheel: true, // 是否开启鼠标滚轮控制swiper切换 ，默认false
      //   direction: "horizontal", // 滑动方向
      //   speed: 900, // 切换速度，自动滑动开始到结束的时间
      //   grabCursor: true, // 悬浮时鼠标样式切换
      //   centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
      //   effect: "slide", // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
      //   fadeEffect: {
      //     crossFade: true,
      //   },
      //   autoplay: true, // 启动自动切换，等同于以下设置
      //   autoplay: {
      //     delay: 2000, // 多少秒切换一次，默认3000ms
      //     disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
      //     waitForTransition: true, // 是否等待过渡完成，再开始自动切换的计时，默认true
      //   },
      //   loop: true, // 循环模式选项

      // },
    };
  },
  mounted() {
    this.getHome();
    this.carouselChange(0);
  },
  methods: {
    // 视频轮播监听滑动事件
    // onSlideChange() {
    //   console.log("Slide change");
    //   // console.log(this.$refs.mySwiper.swiper.activeIndex);
    //   var activeIndex = this.$refs.mySwiper.swiper.activeIndex;
    //   const videos = this.$refs.videos;
    //   if (activeIndex == 1 || activeIndex == 5) {
    //     this.swiperindex = 0;
    //     console.log(this.swiperindex);
    //     this.autoplay = false;
    //     videos[0].currentTime = 0; // 将视频回到起始时间
    //     videos[0].pause(); // 暂停视频播放

    //     setTimeout(function () {
    //       videos[0].play();
    //     }, 300);
    //   } else {
    //     this.swiperindex = activeIndex - 1;
    //     console.log(this.swiperindex);
    //   }
    // },
    getHome() {
      getHomeBanner({}).then((data) => {
        console.log(data);
        this.list = data.data;
      });
    },
    //轮播
    carouselChange(index) {
      // console.log(index);
      this.swiperindex = index;
      // const videos = this.$refs.videos;
      // if(videos.length === 0) return
      // if (index == 0) {
      //   this.autoplay = false;
      //   videos[0].currentTime = 0; // 将视频回到起始时间
      //   videos[0].pause(); // 暂停视频播放

      //   setTimeout(function () {
      //     videos[0].play();
      //   }, 100);
      // }
    },
    playNextVideo(index) {
      // console.log("视频播放完了");
      // if (index == this.list.length - 1) {
      //   this.carouselChange(index);
      // } else {
      //   this.autoplay = true;
      // }
      this.autoplay = true;
    },
    bannerjump(index) {
      // console.log(index);
    },
    // 跳转详情
    know(item) {
      console.log(item, 2452454545);
      this.$router.push({
        path: item.url,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("../common/style.css");
::v-deep .el-carousel__item {
  img {
    object-fit: cover;
  }
}
.xia {
  position: absolute;
  bottom: 0.16rem !important;
  left: 48%;
  z-index: 3;
  width: 0.42rem;
  height: 0.42rem;
  animation: translate-xia 2s infinite linear;
}

.zixie {
  position: absolute;
  bottom: 0 !important;
  padding-bottom: 1.06rem !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.72) 100%
  );
  z-index: 2;
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .zixie-item {
    display: flex;
    flex-direction: column;
    width: 25%;
    // cursor: pointer;
  }

  .title2 {
    text-align: center;
    line-height: 0.56rem;
    font-size: 0.14rem;
    color: #fff;
    white-space: nowrap;
  }

  .xian {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    position: relative;
  }

  .jindu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #cd000c;
    height: 200%;
    display: inline-block;
    animation: anmite1 2.5s linear;
    opacity: 1;
  }

  .jindu1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #cd000c;
    height: 200%;
    display: inline-block;
    animation: anmite1 9s linear;
    opacity: 1;
  }
}

.title {
  position: absolute;
  top: 1.98rem;
  left: 8%;
  font-family: Microsoft YaHei;
  font-size: 0.5rem;
  font-weight: 700;
  line-height: 0.81rem;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.subtitle {
  top: 2.74rem;
}

.but {
  position: absolute;
  top: 54%;
  left: 8%;
  width: max-content;
  padding: 0.16rem 0.26rem;
  // height: 0.6rem;
  color: rgba(255, 255, 255, 1);
  border: 1px solid #cd000c;
  background: #cd000c;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  z-index: 3;
  transition: all 0.3s linear;
  font-size: 0.16rem;
}

// .but:hover {
//   background: #cd000c;
//   border: 1px solid #cd000c;
// }
// @themeColor: #1890FF;
// .swiper-container {
//   --swiper-theme-color: @themeColor; /* 设置Swiper风格 */
//   --swiper-navigation-color: @themeColor; /* 单独设置按钮颜色 */
//   --swiper-navigation-size: 48px; /* 设置导航按钮大小 */
//   --swiper-pagination-color: #00ff33; /* 单独设置分页导航颜色 */
//   --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
//   --swiper-preloader-color: @themeColor; /* 单独设置预加载圆圈的颜色 */
//   margin: 0 auto;
//   .swiper-wrapper {
//     // 自动切换过渡效果设置
//     transition-timing-function: ease;
//     -webkit-transition-timing-function: ease;
//     .swiper-slide {
//       // 懒加载时背景图
//       // background: url(~@/assets/images/default.png) no-repeat center;
//       background-color: #000;
//       background-size: cover;
//       .u-img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//       }
//       .default-in {
//         // 缩小
//         // transition: 1s linear 2s;
//         // transform: scale(1);
//         // -webkit-transform: scale(1);
//       }
//       .default-out {
//         // 放大
//         // transition: 1s linear 2s;
//         // transform: scale(1.1);
//         // -webkit-transform: scale(1.1);
//       }
//     }
//     .swiper-slide-active,
//     .swiper-slide-duplicate-active {
//       .zoomin {
//         // 放大
//         // transition: 6s linear;
//         // transform: scale(1.1);
//         // -webkit-transform: scale(1.1);
//       }
//       .zoomout {
//         // 缩小
//         // transition: 6s linear;
//         // transform: scale(1);
//         // -webkit-transform: scale(1);
//       }
//     }
//     .swiper-lazy {
//       width: 100%;
//       height: 100%;
//       background-size: cover;
//     }
//   }
//   .swiper-pagination {
//     bottom: 0 !important;
//     padding-bottom: 1.06rem !important;
//     background: linear-gradient(
//       180deg,
//       rgba(0, 0, 0, 0) 0%,
//       rgba(0, 0, 0, 0.72) 100%
//     );

//     // 自定义分页器样式
//     ::v-deep .swiper-pagination-bullet {
//       width: 23%;
//       height: 1px;
//       text-align: center;
//       line-height: 0.56rem;
//       font-size: 0.14rem;
//       color: #fff;
//       opacity: 1;
//       background: rgba(255, 255, 255, 0.5);
//       margin: 0;
//     }
//     ::v-deep .swiper-pagination-bullet-active {
//       // color: #fff;
//       background: #cd000c;
//       // animation: anmite1 3s linear;
//     }
//   }
//   /*先去掉默认样式*/
//   .swiper-button-prev:after {
//     display: none;
//   }
//   .swiper-button-next:after {
//     display: none;
//   }
//   /*再自定义样式*/
//   .swiper-button-prev {
//     width: 50px;
//     height: 50px;
//     background: url("../assets/home/left.png") no-repeat;
//     bottom: 15px;
//   }
//   .swiper-button-next {
//     width: 50px;
//     height: 50px;
//     background: url("../assets/home/right.png") no-repeat;
//     bottom: 15px;
//   }
// }
</style>