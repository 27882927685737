import enLocale from 'element-ui/lib/locale/lang/en' //引入element语言包
const en = {
  header: {
    index: 'HOME',
    zoujin: 'ABOUT US',
    zoujinerji: {
      jianjie: 'Henglian Profile',
      wenhua: 'Henglian Culture',
      licheng: 'Milestones',
      rongyu: 'Group Honors'
    },
    chanye: 'INDUSTRIAL LAYOUT',
    chanyerji: {
      shengwu: 'Biofiber Industry',
      tezhong: 'Specialty Paper Industry',
      weisheng: 'Hygienic Material Industry',
      qita: 'Other Industries'
    },
    zhuoyue: 'TECHNOLOGY',
    zhuoyuerji: {
      jishu: 'Technical Center',
      chanxue: 'Industry-university-research Cooperation',
      zhuanli: 'Patent and S&T Achievements'
    },
    xinwen: 'NEWS CENTER',
    xinwenerji: {
      jituan: 'Group News',
      hangye: 'Industry Information',
      quanmeiti: 'All Media Matrix'
    },
    zeren: 'SUSTAINABLE DEVELOPMENT',
    zerenerji: {
      huanjing: 'Recycling Economy',
      gongyi: 'Social Welfare'
    },
    rencai: 'GAREERS',
    rencaierji: {
      zhanlue: 'Talent Strategy',
      zhaopin: 'Recruitment Information',
      huodong: 'Employee Activities'
    },
    lianxi: 'CONTACT US'
  },
  placeholder: 'Please enter keywords',
  search: 'search',
  look: 'View More',
  aboutHeng: 'ABOUT HENGLIAN',
  indexLayout: 'INDUSTRIAL LAYOUT',
  indexTechnology: 'TECHNOLOGY',
  indexHonor: 'GROUP HONORS',
  indexNew: 'NEWS CENTER',
  indexLearn: 'Learn more information',
  topTen: 'Top Ten Concepts',
  technicalCenter: 'Technical Center',
  technologyInfor: 'Technology Innovation Information',
  majorInnova: 'Major innovation',
  nationalPat: 'National patent',
  inventionPat: 'Invention patent',
  drafting: 'Drafting standards',
  scienceTec: 'S&T progress award',
  groupNews: 'Group News',
  industryInfor: 'Industry information',
  all: 'All',
  mediaTitle: 'All media matrix',
  welfareTitle: 'Social Welfare',
  environment: 'Recycling Economy',
  publicWelfare: 'Social Welfare',
  employeActive: 'Employee Activities',
  marketDroup: 'Marketing Network',
  address: 'Add.',
  phone: 'Tel.',
  email: 'E-mail',
  code: 'P.C.',
  name: 'Name',
  relationGroup: 'Region',
  textMessage: 'Requirement Description',
  submitNow: 'Submit Now',
  submitSuccess: 'Submitted successfully',
  coop: 'We will contact you as soon as possible. Thank you for your attention to Henglian Group!',
  know: 'Got it',
  submitFail: 'Submission failed',
  submitPublic: 'It may be an issue with your network environment or other issues, please resubmit~',
  back: 'Return to retry',
  relationMessage: 'Online Message',
  messageText: 'If you need any help or consultation, please feel free to contact us and we will be happy to serve you!<br/>We will keep personal information collected through this website confidential.',
  pleaseName: 'Please enter your name',
  pleasePhone: 'Please enter phone number',
  pleaseMobile: 'Please enter the correct phone number',
  pleaseEmail: 'Please enter your email address',
  pleaseTrue: 'Please enter the correct email address',
  comapanyIndu: 'Company Profile',
  leadProducts: 'Leading Products',
  relationPop: 'We are always by your side. If you need any help or consultation, please feel free to contact us and we will be happy to serve you!',
  year: 'year',
  swiperSee: 'Swipe left and right to see more',
  million: 'million',
  totalAsset: 'Total value',
  numberEmploy: 'Employees',
  professionalTec: 'Professionals',
  deeply: 'Industry',
  mediaLLook: 'Click on the company you want to know to view media',
  noFurther: 'No further data available at the moment',
  previousUp: 'Previous article',
  previousDown: 'Next article',
  detailCn: 'Detail',
  backList: 'Return to List',
  viewVolume: 'View volume',
  groupStation: 'GROUP STATION GROUP',
  viewJob: 'View detailed job information',
  layoutContact: 'Contact Us',
  ...enLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
}
export default en;
