<template>
  <div>
    <div class="main">
      <div class="top">
        <img
          src="../assets/footlogo.png"
          style="width: 2.36rem; height: 0.32rem"
          alt=""
        />
        <img
          @click="goTop"
          src="../assets/home/foottop.png"
          style="width: 0.35rem; height: 0.35rem; cursor: pointer"
          alt=""
        />
      </div>
      <div class="mid">
        <div class="item" v-for="(item, index) in tabarlist" :key="index">
          <p class="title" @click="titleClick(item)">{{ item.title }}</p>
          <div
            style="
              width: 0.4rem;
              height: 0.02rem;
              background-color: #cd000c;
              margin-bottom: 0.15rem;
            "
          ></div>
          <div v-if="index != 6">
            <div v-for="(it, index) in item.erji" :key="index">
              <p @click="toPageDetails(it, item)" class="p2">{{ it }}</p>
            </div>
          </div>
          <div v-if="getLangText === 'CN'">
            <div class="index6" v-if="index == 6">
              <div v-for="(item, index) in qrlist" :key="index">
                <img
                  :src="item.img"
                  alt=""
                  @mouseenter="handleMouseEnter(index)"
                />
                <transition name="fade">
                  <img
                    v-show="index == imgindex"
                    :class="'qr' + index"
                    class="qr"
                    :src="item.qr"
                    style=""
                    alt=""
                  />
                </transition>
              </div>
            </div>
          </div>
          <div v-if="getLangText === 'EN'">
            <div class="index6" v-if="index == 4">
              <div v-for="(item, index) in qrlist" :key="index">
                <img
                  :src="item.img"
                  alt=""
                  @mouseenter="handleMouseEnter(index)"
                />
                <transition name="fade">
                  <img
                    v-show="index == imgindex"
                    :class="'qr' + index"
                    class="qr"
                    :src="item.qr"
                    style=""
                    alt=""
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="p3">{{ $t("groupStation") }}</div>
        <div class="item" v-show="staFalse">
          <div
            class="p4"
            :class="'p4' + index"
            v-for="(item, index) in company"
            :key="index"
            @click="companyClick(item)"
          >
            <!-- <div @click="companyClick(item)">{{ item.name }}</div> -->
            {{ item.name }}
          </div>
        </div>
        <div class="item" v-show="staTrue">
          <div
            class="p4"
            :class="'p4' + index"
            v-for="(item, index) in company"
            :key="index"
            @click="companyClick(item)"
          >
            <!-- <div @click="companyClick(item)">{{ item.name }}</div> -->
            {{ item.name }}
          </div>
        </div>
        <div class="item footStation" v-if="getLangText === 'EN'">
          <div
            class="ltdstation"
            :class="'ltdstation' + index"
            v-for="(item, index) in company"
            :key="index"
            @click="companyClick(item)"
          >
            <!-- <div @click="companyClick(item)">{{ item.name }}</div> -->
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="foott">
      <p>
        Copyright © 2013 - 2024 Henglian Group. All Rights Reserved.<span
          style="color: rgba(255, 255, 255, 0.6); margin-left: 0.16rem"
          >POWERED BY UG</span
        >
      </p>
      <!-- <p style="cursor: pointer">隐私政策</p> -->
    </div>
  </div>
</template>
<script>
import { getFootLink, getGroupStation } from "@/api/index";
export default {
  data() {
    return {
      getLangText: localStorage.getItem("lang") || "CN",
      tabarlist: [
        {
          path: "/about",
          title: this.$t("header.zoujin"),
          erji: [
            this.$t("header.zoujinerji.jianjie"),
            this.$t("header.zoujinerji.wenhua"),
            this.$t("header.zoujinerji.licheng"),
            this.$t("header.zoujinerji.rongyu"),
          ],
        },
        {
          path: "/layoutView",
          title: this.$t("header.chanye"),
          erji: [
            this.$t("header.chanyerji.shengwu"),
            this.$t("header.chanyerji.tezhong"),
            this.$t("header.chanyerji.weisheng"),
            this.$t("header.chanyerji.qita"),
          ],
        },
        {
          path: "/technologyView",
          title: this.$t("header.zhuoyue"),
          erji: [
            this.$t("header.zhuoyuerji.jishu"),
            this.$t("header.zhuoyuerji.chanxue"),
            this.$t("header.zhuoyuerji.zhuanli"),
          ],
        },
        {
          path: "/newsView",
          title: this.$t("header.xinwen"),
          erji: [
            this.$t("header.xinwenerji.jituan"),
            this.$t("header.xinwenerji.hangye"),
            this.$t("header.xinwenerji.quanmeiti"),
          ],
        },
        {
          path: "/developmentView",
          title: this.$t("header.zeren"),
          erji: [
            this.$t("header.zerenerji.huanjing"),
            this.$t("header.zerenerji.gongyi"),
          ],
        },
        {
          path: "/professionalView",
          title: this.$t("header.rencai"),
          erji: [
            this.$t("header.rencaierji.zhanlue"),
            this.$t("header.rencaierji.zhaopin"),
            this.$t("header.rencaierji.huodong"),
          ],
        },
        {
          path: "/relationView",
          title: this.$t("header.lianxi"),
        },
      ],
      company: [],
      qrlist: [
        {
          img: require("../assets/home/foot1.png"),
          qr: require("../assets/home/weixin.jpg"),
        },
        {
          img: require("../assets/home/foot2.png"),
          qr: require("../assets/home/douyin.png"),
        },
        {
          img: require("../assets/home/foot3.png"),
          qr: require("../assets/home/shipinhao.jpg"),
        },
      ],
      imgindex: 1,
      staFalse: true,
      staTrue: true,
    };
  },
  mounted() {
    this.getFoot();
    this.getFootGroup();
    if (this.getLangText === "CN") {
      this.staFalse = false;
      // this.staTrue = true
    }
    if (this.getLangText === "EN") {
      this.staTrue = false;
      this.staFalse = false;
    }
    if (this.getLangText == "EN") {
      this.tabarlist = this.tabarlist.filter(
        (item) => item.title !== "NEWS CENTER"
      );
      this.tabarlist = this.tabarlist.filter(
        (item) => item.title !== "GAREERS"
      );
      console.log(this.tabarlist);
    }
  },
  methods: {
    toPageDetails(it, item) {
      if (item.path === this.$route.path) {
        this.$emit("getPageName", it);
      } else {
        this.$router.push({
          path: item.path,
        });
        window.sessionStorage.setItem("anchorPoint", it);
      }
    },
    titleClick(item) {
      this.$router.push({
        path: item.path,
      });
    },
    goTop() {
      // 使用 scrollTo() 函数将视口滚动到页面顶部
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleMouseEnter(index) {
      this.imgindex = index;
    },
    getFoot() {
      getFootLink({}).then((data) => {
        // console.log(data, "foot");
        this.$emit("getFoot", data.data);
      });
    },
    getFootGroup() {
      getGroupStation({}).then((data) => {
        console.log(data, "集团站群");
        this.company = data.data;
      });
    },
    companyClick(item) {
      window.open(item.url);
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s ease-in 0s;
  scale: (1);
}

.fade-enter {
  opacity: 0;
  scale: (0);
}

.main {
  width: 100%;
  // height: 5.83rem;
  background: #1b1b1b;
  color: #fff;
  padding: 0.68rem 1.59rem 0;
  box-sizing: border-box;

  p {
    cursor: pointer;
    transition: all 0.3s linear;
  }

  p:hover {
    color: #cd000c;
  }

  .index6 {
    display: flex;
    width: 1.22rem;
    height: 1.56rem;
    justify-content: space-between;
    // overflow: hidden;
    position: relative;

    img {
      width: 0.3rem;
      height: 0.3rem;
      cursor: pointer;
      transition: all 0.3s linear;
    }

    img:hover {
      scale: (1.2);
    }

    .qr {
      width: 0.8rem;
      height: 0.8rem;
      border: 0.14rem solid #fff;
      border-radius: 0.06rem;
      margin-top: 0.16rem;
      position: absolute;
      background: #fff;
    }

    .qr0 {
      left: -0.4rem;
    }

    .qr1 {
      left: 0.1rem;
    }

    .qr2 {
      left: 0.5rem;
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e3e3e3;
  }

  .mid {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-top: 0.24rem;

    .title {
      font-family: Microsoft YaHei;
      font-size: 0.16rem;
      font-weight: 700;
      line-height: 0.56rem;
      text-align: left;
    }

    .p2 {
      font-family: Microsoft YaHei;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.35rem;
      text-align: left;
    }
  }

  .bottom {
    width: 100%;
    margin-top: 0.36rem;
    padding-bottom: 0.28rem;

    .p3 {
      font-family: Microsoft YaHei;
      font-size: 0.16rem;
      font-weight: 700;
      text-align: left;
    }

    .item {
      margin-top: 0.08rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-family: Microsoft YaHei;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.24rem;
      text-align: left;
    }

    .p4 {
      color: rgba(255, 255, 255, 0.6);
      transition: all 0.3s linear;
      cursor: pointer;
      // flex: 0 0 16.6%;
      margin-top: 0.04rem;
      margin-right: 0.4rem;
    }

    .p4:hover {
      color: #cd000c;
    }
    .p47 {
      margin-left: 0.16rem;
    }
    .p48 {
      margin-left: 0.32rem;
    }
  }
}
@media (min-width: 2000px) and (max-width: 2300px) {
  .p47 {
    margin-left: 0.16rem !important;
  }
  .p48 {
    margin-left: 0.32rem !important;
  }
}
@media (min-width: 2188px) and (max-width: 2399px) {
  .p47 {
    margin-left: 0 !important;
  }
  .p48 {
    margin-left: 0.64rem !important;
  }
}
@media (min-width: 2400px) and (max-width: 2600px) {
  .p48 {
    margin-left: 0 !important;
  }
}
.foott {
  width: 100%;
  height: 0.6rem;
  background: rgba(205, 0, 12, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.59rem;
  box-sizing: border-box;
  color: #fff;
  font-family: Microsoft YaHei;
  font-size: 0.14rem;
  font-weight: 400;
  text-align: left;
}

.footStation {
  margin-top: 0.08rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: Microsoft YaHei;
  font-size: 0.16rem;
  font-weight: 400;
  line-height: 0.24rem;
  text-align: left;
}

.ltdstation {
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.3s linear;
  cursor: pointer;
  margin-top: 0.04rem;
  margin-right: 0.9rem;
}

.ltdstation:hover {
  color: #cd000c;
}
.ltdstation4 {
  margin-left: 0.64rem;
}
.ltdstation5 {
  margin-left: 2.22rem;
}
.ltdstation6 {
  margin-right: 0;
}
.ltdstation7 {
  margin-left: 0.3rem;
}
.ltdstation8 {
  margin-left: 0.46rem;
}
@media (min-width: 1000px) and (max-width: 2100px) {
  .ltdstation4 {
    margin-left: 0.53rem;
  }
  .ltdstation5 {
    margin-left: 2.26rem;
  }
  .ltdstation6 {
    margin-left: 0rem;
  }
  .ltdstation7 {
    margin-left: 0.22rem;
  }
  .ltdstation8 {
    margin-left: 0.41rem;
  }
}
@media (min-width: 2100px) and (max-width: 2166px) {
  .ltdstation4 {
    margin-left: 0;
  }
  .ltdstation5 {
    margin-left: 2.7rem;
  }
  .ltdstation6 {
    margin-left: 0.23rem;
  }
  .ltdstation7 {
    margin-left: 0.53rem;
  }
  .ltdstation8 {
    margin-left: 0;
  }
}
@media (min-width: 2400px) and (max-width: 2900px) {
  .ltdstation4 {
    margin-left: 0;
  }
  .ltdstation5 {
    margin-left: 0;
  }
  .ltdstation6 {
    margin-left: 0.76rem;
  }
  .ltdstation7 {
    margin-left: 0.53rem;
  }
  .ltdstation8 {
    margin-left: 0.32rem;
  }
}
</style>