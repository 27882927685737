import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入全局css
import './assets/total.css'
// 引入全局rem.js
import './assets/rem'
// 导入i18n
import i18n from './utils/i18n/i18n';

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'

// 引入 Element 的全部组件
import ElementUI from 'element-ui'
// 引入 Element 样式
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'



// axios
// 在main.js中 导入 axios，在组件中使用时不需要再进行导入
// import axios from 'axios'
// 全局配置 axios 的请求根路径
// axios.defaults.baseURL = ''
// 把axios挂载到Vue的原型上
// Vue.prototype.$http = axios
// 在每个vue组件中要发起请求，直接调用this.$http.xxx

// icon
import './assets/font/iconfont.css'

Vue.use(ElementUI, { locale })
// 全局挂载
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
