import axios from 'axios'
import { Message } from 'element-ui'
let getLangDataText = localStorage.getItem('lang')
let lang = getLangDataText ? getLangDataText.toUpperCase() : 'CN';
if (process.env.NODE_ENV === 'development') {
    // 开发环境
    axios.defaults.baseURL = 'https://www.henglianpaper.cn/api'
    console.log('开发环境');
} else if (process.env.NODE_ENV === 'debug') {
    // 测试环境
    axios.defaults.baseURL = ''
    // console.log('测试环境');
} else if (process.env.NODE_ENV === 'production') {
    // 生产环境
    axios.defaults.baseURL = 'https://www.henglianpaper.cn/api'
    // console.log('生产环境');
}
axios.interceptors.request.use(
    config => {
        // console.log('config', config);
        // config.headers.Authorization = token // 请求头添加token
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)
// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        console.log(error);
        if (error.response.status) {
            switch (error.response.status) {
                case 500:
                    Message.error('系统异常！')
                    break;
                case 403:
                    Message.error('登录过期，请重新登录')
                    break;
                default:
                    Message.error('其他错误')
            }
            return Promise.reject(error.response)
        }
    }
)
function setRequest({ url, method, data, params }) {
    return new Promise((resolve, reject) => {
        axios.request({
            url: url,
            method: method,
            headers: {},
            data: data ? { ...data, language: lang } : data,
            params: params ? { ...params, language: lang } : params,
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.dir(err);
            Message.error('错误' + err)
            reject(err)
        })
    })
}
export default setRequest;