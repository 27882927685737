<template>
  <div>
    <div class="tab" v-show="navshow">
      <div class="logo"></div>
      <div style="width: 11.56rem; display: flex; align-items: center">
        <router-link
          :to="item.path"
          v-for="(item, index) in tabarlist"
          :key="index"
        >
          <div
            class="aDiv"
            @mouseenter="handleMouseEnter(index)"
            @mouseleave="handleMouseLeave"
          >
            <span>{{ item.title }}</span>
          </div>
        </router-link>
      </div>
      <div class="right">
        <div class="right-img1" @mouseenter="handleMouseEnter1()"></div>
        |
        <div class="right-img2" @click="changeLang(getLangData)">
          {{ getLangData == "CN" ? "EN" : "CN" }}
        </div>
      </div>
    </div>
    <div class="nav" v-show="!navshow">
      <div class="logo"></div>
      <div style="width: 11.56rem; display: flex; align-items: center">
        <router-link
          :to="item.path"
          v-for="(item, index) in tabarlist"
          :key="index"
        >
          <div
            class="aDiv"
            @mouseenter="handleMouseEnter(index)"
            @mouseleave="handleMouseLeave"
          >
            <span>{{ item.title }}</span>
          </div>
        </router-link>
      </div>
      <div class="right">
        <div class="right-img1" @mouseenter="handleMouseEnter1()"></div>
        |
        <div class="right-img2" @click="changeLang(getLangData)">
          {{ getLangData == "CN" ? "EN" : "CN" }}
        </div>
      </div>
    </div>
    <div v-show="popshow">
      <div v-for="(item, index) in tabarlist" :key="index">
        <div
          class="pop"
          v-show="index == hoverindex && index != 0 && index != 7"
          @mouseenter="handleMouseEnter(index)"
          @mouseleave="handleMouseLeave"
        >
          <div class="left">
            <div
              @click="toPageDetails(it, item)"
              class="title2"
              v-for="(it, index) in item.erji"
              :key="index"
            >
              {{ it }}
            </div>
          </div>
          <div class="right">
            <p v-html="item.texti"></p>
            <img
              :src="item.imgi"
              style="width: 4.61rem; height: 2.59rem"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="search"
      v-show="search"
      @mouseenter="handleMouseEnter1()"
      @mouseleave="handleMouseLeave1"
    >
      <div style="display: flex; align-items: center">
        <input
          @keyup.enter="getSearchDataToPage"
          v-model="searchValue"
          :placeholder="inputPlace"
          type="text"
        />
        <div class="but" @click="getSearchDataToPage">
          {{ this.$t("search") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTitleText } from "@/api/index";
export default {
  data() {
    return {
      getLangData: localStorage.getItem("lang") || "CN",
      tabarlist: [
        {
          path: "/",
          title: this.$t("header.index"),
        },
        {
          path: "/about",
          title: this.$t("header.zoujin"),
          erji: [
            this.$t("header.zoujinerji.jianjie"),
            this.$t("header.zoujinerji.wenhua"),
            this.$t("header.zoujinerji.licheng"),
            this.$t("header.zoujinerji.rongyu"),
          ],
        },
        {
          path: "/layoutView",
          title: this.$t("header.chanye"),
          erji: [
            this.$t("header.chanyerji.shengwu"),
            this.$t("header.chanyerji.tezhong"),
            this.$t("header.chanyerji.weisheng"),
            this.$t("header.chanyerji.qita"),
          ],
        },
        {
          path: "/technologyView",
          title: this.$t("header.zhuoyue"),
          erji: [
            this.$t("header.zhuoyuerji.jishu"),
            this.$t("header.zhuoyuerji.chanxue"),
            this.$t("header.zhuoyuerji.zhuanli"),
          ],
        },
        {
          path: "/newsView",
          title: this.$t("header.xinwen"),
          erji: [
            this.$t("header.xinwenerji.jituan"),
            this.$t("header.xinwenerji.hangye"),
            this.$t("header.xinwenerji.quanmeiti"),
          ],
        },
        {
          path: "/developmentView",
          title: this.$t("header.zeren"),
          erji: [
            this.$t("header.zerenerji.huanjing"),
            this.$t("header.zerenerji.gongyi"),
          ],
        },
        {
          path: "/professionalView",
          title: this.$t("header.rencai"),
          erji: [
            this.$t("header.rencaierji.zhanlue"),
            this.$t("header.rencaierji.zhaopin"),
            this.$t("header.rencaierji.huodong"),
          ],
        },
        {
          path: "/relationView",
          title: this.$t("header.lianxi"),
        },
      ],
      inputPlace: this.$t("placeholder"),
      hoverindex: 1,
      popshow: false,
      navshow: true,
      search: false,
      searchValue: "",
    };
  },
  mounted() {
    window.addEventListener("wheel", this.closeSelect);
    this.getText();
    if ((this.getLangData == "EN")) {
      this.tabarlist = this.tabarlist.filter(
        (item) => item.title !== "NEWS CENTER"
      );
      this.tabarlist = this.tabarlist.filter(
        (item) => item.title !== "GAREERS"
      );
      console.log(this.tabarlist);
    }
  },
  activated() {
    this.periodicRefresh();
    window.addEventListener("wheel", this.closeSelect);
  },
  deactivated() {
    // 页面关闭（路由跳转）时清除定时器
    clearInterval(this.queueTableDataTimer);
    this.queueTableDataTimer = null;
    // 页面跳转清除 滚动事件
    window.removeEventListener("wheel", this.closeSelect);
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.closeSelect);
  },
  methods: {
    // 切换语言
    changeLang(lang) {
      // return
      console.log(lang);
      let langTetx = lang === "CN" ? "EN" : "CN";
      localStorage.setItem("lang", langTetx);
      this.$i18n.locale = langTetx;
      window.location.reload();
      // this.$router.go(0);
    },
    getSearchDataToPage() {
      if (!this.searchValue) return;
      this.$router.push({
        path: "/searchView",
        params: {
          search: this.searchValue,
        },
      });
    },
    handleMouseEnter(index) {
      // 处理鼠标移入事件
      // console.log("Mouse entered router-link", index);
      if (this.getLangData == "EN" && index == 5) return
      this.navshow = false;
      this.search = false;
      this.popshow = true;
      this.hoverindex = index;
    },
    getText() {
      getTitleText({}).then((data) => {
        console.log(data, "导航栏文本");
        this.tabarlist = this.tabarlist.map((item) => {
          let indexi = data.data.find((items) => items.name === item.title);
          let obj = {};
          if (indexi) {
            obj = {
              ...item,
              texti: indexi.text,
              imgi: indexi.image,
            };
          } else {
            obj = {
              ...item,
            };
          }
          return obj;
        });
        console.log(this.tabarlist);
      });
    },
    handleMouseLeave() {
      this.popshow = false;
      this.search = false;
    },
    toPageDetails(it, item) {
      if (item.path === this.$route.path) {
        this.$emit("getPageName", it);
      } else {
        this.$router.push({
          path: item.path,
        });
        window.sessionStorage.setItem("anchorPoint", it);
      }
    },
    closeSelect() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log("滚动监听到了", scrollTop);
      const tab = document.querySelector(".tab");
      const nav = document.querySelector(".nav");
      if (scrollTop > 600 || this.popshow == true) {
        nav.style.animation = "opa 0.5s linear";
        // tab.style.animation = "opa1 0.3s ease";
        // nav.style.animation = "opa 0.3s ease";
        this.navshow = false;
      } else if (scrollTop < 600) {
        tab.style.animation = "opa 0.5s linear";
        // tab.style.animation = "opa 0.3s ease";
        // nav.style.animation = "opa1 0.3s ease";
        this.navshow = true;
      }
    },
    handleMouseEnter1() {
      this.navshow = false;
      this.search = true;
    },
    handleMouseLeave1() {
      if (this.$route.name === "SearchView") return;
      this.search = false;
    },
    searchvalue() {
      this.$router.push({ path: "./SearchView" });
    },
  },
};
</script>
<style lang="scss" scoped>
.tab {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  opacity: 1;
  pointer-events: all;
  backdrop-filter: saturate(180%) blur(5px);
  // filter: blur(5px); /* 调整模糊程度 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.8rem;
  box-sizing: border-box;
  padding: 0 1rem;
  color: rgba(51, 51, 51, 1);
  font-size: 0.16rem;

  .aDiv {
    height: 100%;
    line-height: 0.8rem;
    cursor: pointer;
    transition: all 0.3s linear;
    padding: 0 0.4rem;

    span {
      height: 100%;
      line-height: 0.8rem;
      transition: all 0.3s linear;
      display: inline-block;
      border-bottom: 0.02rem solid rgb(0, 0, 0, 0);
      white-space: nowrap;
    }
  }

  a {
    color: rgba(51, 51, 51, 1) !important;

    &.router-link-exact-active {
      color: rgba(205, 0, 12, 1) !important;

      span {
        display: inline-block;
        height: 100%;
        line-height: 0.8rem;
        border-bottom: 0.02rem solid rgb(205, 0, 12);
      }
    }
  }
}

.nav {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  pointer-events: all;
  backdrop-filter: saturate(180%) blur(5px);
  // filter: blur(5px); /* 调整模糊程度 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.8rem;
  box-sizing: border-box;
  padding: 0 1rem;
  color: rgba(51, 51, 51, 1);
  font-size: 0.16rem;
  border-bottom: 1px solid rgba(239, 239, 239, 1);

  .aDiv {
    height: 100%;
    line-height: 0.8rem;
    cursor: pointer;
    transition: all 0.3s linear;
    padding: 0 0.4rem;

    span {
      height: 100%;
      line-height: 0.8rem;
      transition: all 0.3s linear;
      display: inline-block;
      border-bottom: 0.02rem solid rgb(0, 0, 0, 0);
      white-space: nowrap;
    }
  }

  a {
    color: rgba(51, 51, 51, 1) !important;

    &.router-link-exact-active {
      color: rgba(205, 0, 12, 1) !important;

      span {
        display: inline-block;
        height: 100%;
        line-height: 0.8rem;
        border-bottom: 0.02rem solid rgb(205, 0, 12);
        white-space: nowrap;
      }
    }
  }
}

a:hover {
  color: rgba(205, 0, 12, 1) !important;

  span {
    display: inline-block;
    border-bottom: 0.02rem solid rgb(205, 0, 12) !important;
    height: 100%;
    line-height: 0.8rem;
    white-space: nowrap;
  }
}

.logo {
  width: 1.78rem;
  height: 0.23rem;
  background-image: url(../assets/1.png);
  background-size: 1.78rem 0.23rem;
}

.right {
  display: flex;
  align-items: center;
  color: rgba(227, 227, 227, 1) !important;

  .right-img1 {
    width: 0.19rem;
    height: 0.19rem;
    margin-right: 0.2rem;
    background-image: url(../assets/home/search1.png);
    background-size: 0.19rem 0.19rem;
    cursor: pointer;
  }

  .right-img2 {
    width: 0.19rem;
    // height: 0.13rem;
    margin-left: 0.2rem;
    // background-image: url(../assets/home/EN1.png);
    // background-size: 0.19rem 0.13rem;
    cursor: pointer;
  }
}

.pop {
  width: 100%;
  background: #fff;
  height: 3.77rem;
  position: fixed;
  top: 0.8rem;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0;
  box-sizing: border-box;
  animation: anmite5 0.3s ease-in;

  .left {
    width: 22%;
    border-right: 1px solid #e3e3e3;
    color: #333;
    // font-family: "Microsoft YaHei";
    font-size: 0.16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 260%;
    /* 46.8px */
    cursor: pointer;

    .title2 {
      text-align: center;
      transition: all 0.3s linear;
    }
  }

  .title2:hover {
    color: rgba(205, 0, 12, 1);
  }

  .right {
    width: 78%;
    display: flex;
    align-items: inherit;
    justify-content: space-between;
    padding-right: 2.1rem;
    padding-left: 0.8rem;

    p {
      color: #666;
      // font-family: "Microsoft YaHei";
      font-size: 0.14rem;
      font-style: normal;
      font-weight: 400;
      line-height: 185%;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 5;
      // overflow: hidden;
      width: 6.65rem;
      text-align: justify;
      overflow: auto;
      padding-right: 0.2rem;
    }
    p::-webkit-scrollbar {
      width: 0.01rem;
      background: #e6e6e6;
    }
    p::-webkit-scrollbar-thumb {
      background: #b0b0b0;
      height: 0.2rem;
      background-color: darkgrey; /* 滑块颜色 */
      border-radius: 0.05rem;
    }
  }
}

.search {
  width: 100%;
  background: #fff;
  height: 2.1 rem;
  position: fixed;
  top: 0.8rem;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: anmite5 0.3s ease-in;

  input {
    width: 6.55rem;
    height: 0.42rem;
    border: none;
    border-bottom: 1px solid rgba(239, 239, 239, 1);
  }

  .but {
    // font-family: Microsoft YaHei;
    font-size: 0.2rem;
    font-weight: 400;
    line-height: 0.4rem;
    text-align: left;
    color: rgba(205, 0, 12, 1);
    cursor: pointer;
    margin-left: 0.2rem;
  }
}
</style>